html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  font-family: 'McKinseySans', sans-serif;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

strong,
b {
  font-weight: bold;
}

em,
i {
  font-style: italic;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #c3c9d0;
  border-radius: 10px;
  border: 1px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #919399;
  border: 0;
}

/* use emotion keyframes after upgrading material UI to v5 */

.logo-animated .first {
  transform: scaleX(0.5);
  animation: logo-animated 3s ease-in-out infinite;
}

.logo-animated .second {
  transform: scaleX(0.5);
  animation: logo-animated 3s ease-in-out 0.2s infinite;
}

.logo-animated .third {
  transform: scaleX(0.5);
  animation: logo-animated 3s ease-in-out 0.4s infinite;
}

.logo-animated .fourth {
  transform: scaleX(0.5);
  animation: logo-animated 3s ease-in-out 0.6s infinite;
}

.logo-animated .fifth {
  transform: scaleX(0.5);
  animation: logo-animated 3s ease-in-out 0.8s infinite;
}

.logo-animated .sixth {
  transform: scaleX(0.5);
  animation: logo-animated 3s ease-in-out 1s infinite;
}

@keyframes logo-animated {
  0% {
    transform: scaleX(0.5);
  }

  30% {
    transform: scaleX(1);
  }

  70% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0.5);
  }
}

.timer-rotation {
  transform-origin: 50% 76%;
  transform-box: fill-box;
  animation: timer-rotation 20s infinite linear;
}

@keyframes timer-rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
